<template>
  <ZMainPage v-loading="loading">
    <div class="pb-4 custom-info" v-if="!isAdd">
      <div class="item" v-for="(item, index) in list1" :key="index">
        <span class="label">{{ item.label }}</span>
        <span class="value">{{ item.value }}</span>
      </div>
    </div>
    <el-form ref="form" :model="formData" :rules="rules" labelWidth="150rem" label-position="left">
      <div class="line-group b-b mb-2" v-if="isAdd">
        <div class="line-item">
          <el-form-item label="客户姓名" prop="name">
            <el-input v-model="formData.name" placeholder="请输入"></el-input>
          </el-form-item>
        </div>
        <div class="line-item">
          <el-form-item label="客户联系电话" prop="phone">
            <el-input v-model="formData.phone" maxlength="11" placeholder="请输入"></el-input>
          </el-form-item>
        </div>
      </div>
      <div class="line-group">
        <div class="line-item" v-if="showName">
          <el-form-item label="客户与学生的关系"><div class="el-input">{{ showName }}</div></el-form-item>
        </div>
        <template v-else>
          <div class="line-item">
            <el-form-item label="客户与学生的关系" prop="relationship">
              <el-select v-model="formData.relationship" placeholder="请选择">
                <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </div>
          <div class="line-item" v-if="formData.relationship === 8">
            <el-form-item label="实际关系" prop="real_relationship">
              <el-input v-model="formData.real_relationship" maxlength="10" show-word-limit placeholder="请输入"></el-input>
            </el-form-item>
          </div>
        </template>
        <div class="line-item">
          <el-form-item label="线索来源" prop="source">
            <el-select v-model="formData.source" placeholder="请选择">
              <el-option v-for="item in options5" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
        </div>
      </div>
      <div class="line-group">
        <div class="line-item">
          <el-form-item label="计划报名校区" prop="school">
            <el-select v-model="formData.school" placeholder="请选择">
              <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
        </div>
        <div class="line-item">
          <el-form-item label="其他联系方式" prop="other_contact">
            <el-input v-model="formData.other_contact" maxlength="20" show-word-limit placeholder="请输入"></el-input>
          </el-form-item>
        </div>
      </div>
      <div class="line-group">
        <div class="line-item">
          <el-form-item label="跟进状态" prop="status">
            <el-select v-model="formData.status" placeholder="请选择">
              <el-option v-for="item in options3" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
        </div>
        <div class="line-item">
          <el-form-item label="意向评级" prop="level">
            <el-select v-model="formData.level" placeholder="请选择">
              <el-option v-for="item in options4" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
        </div>
      </div>
      <div class="line-group">
        <div class="line-item">
          <el-form-item label="本次跟进的内容说明" class="single-line">
            <span slot="label" style="float: none !important; word-break: break-word !important;">本次跟进的内容说明</span>
            <el-input v-model="formData.content" placeholder="请输入" type="textarea" maxlength="300" show-word-limit :autosize="{ minRows: 4, maxRows: 6 }"></el-input>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <Dialog :visible.sync="visible" title="跟进记录" :showFooter="false">
      <TimeLine :list="list2" reverse v-loading="loading2"></TimeLine>
    </Dialog>
    <template #footer>
      <el-button type="primary" @click="handleSubmit('form')">提交</el-button>
      <el-button v-if="!isAdd" @click="handleVisible">跟进记录</el-button>
      <el-button @click="handleBack('form')">取消</el-button>
    </template>
  </ZMainPage>
</template>

<script>
import Dialog from "@/components/common/Dialog";
import TimeLine from "@/components/common/TimeLine";
import http from "@/api";
import { changeOption } from "@/utils";
export default {
	_config:{"route":[{"path":"detail","meta":{"title":"跟进"}}, {"path":"add","meta":{"title":"新增"}}]},
  components: { Dialog, TimeLine },
  data() {
    const validatorPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入手机号'))
      } else if (!/^1\d{10}$/.test(value)) {
        callback(new Error('请输入正确手机号'))
      } else {
        callback()
      }
    }
    const validatorName = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入姓名'))
      } else if (value.length > 15) {
        callback(new Error('名字限制15个字'))
      } else {
        callback()
      }
    }
    return {
      formData: { source:'', name: '', phone: '', relationship:'', real_relationship: '', school: '', other_contact: '', status: '', level: '', content: '' },
      rules: {
        name: [{ required: true, validator: validatorName, trigger: 'blur' }],
        phone: [{ required: true, validator: validatorPhone, trigger: 'blur' }],
        relationship: [{ required: false, message: '请选择', trigger: 'change' }],
        real_relationship: [{ required: false, message: '请输入', trigger: 'blur' }],
        school: [{ required: false, message: '请选择', trigger: 'change' }],
        other_contact: [{ required: false, message: '请输入', trigger: 'blur' }],
        status: [{ required: false, message: '请选择', trigger: 'change' }],
        level: [{ required: false, message: '请选择', trigger: 'change' }],
      },
      options1: [],
      options2: [],
      options3: [],
      options4: [],
      options5: [],
      list1: [],
      list2: [],
      visible: false,
      isRequest: false,
      loading: false,
      loading2: false,
      isAdd: false,
      id: 0,
      showName: ''
    }
  },
  created() {
    this.id = Number(this.$route.query.id) || 0
    this.getConfig()
    this.getData()
    if(this.$route.path.includes('add')) {
      this.isAdd = true
    }
  },
  methods: {
    getConfig() {
      http.get('/zhaosheng/api/crm/thread-config').then(res => {
        const { status = [], relationship = [], level = [], school = [] } = res
        this.options1 = changeOption({ list: relationship, label: 'name', value: 'id' })
        this.options2 = changeOption({ list: school, label: 'school_name', value: 'id' })
        this.options3 = changeOption({ list: status, label: 'name', value: 'id' }).filter(item => item.value)
        this.options4 = changeOption({ list: level, label: 'name', value: 'id' })
      })
      http.get('/zhaosheng/api/crm/search-source', { keyword: '' }).then(res => {
        this.options5 = res.map(item => ({ ...item, label: item.source, value: item.id }))
      })
    },
    getData() {
      if(!this.id) return
      this.loading = true
      http.get('/zhaosheng/api/crm/thread-detail', { id: this.id }).then(res => {
        const { source, name, phone, creator, created_at, real_relationship, status, level, relationship, other_contact, school, content, relationship_map } = res
        this.list1 = [
          { label: '客户姓名', value: name },
          { label: '客户联系电话', value: phone },
          { label: '线索归属人', value: creator },
          { label: '线索提交时间', value: created_at }
        ]
        this.formData.source = source
        this.formData.relationship = relationship
        this.formData.real_relationship = real_relationship
        this.formData.school = school
        this.formData.other_contact = other_contact
        this.formData.status = status || ''
        this.formData.level = level
        this.formData.content = content
        if(relationship) {
          const relationshipObj = relationship_map.find(item => item.id === relationship)
          this.showName = real_relationship || relationshipObj?.name || ''
        }
      }).finally(()=> this.loading = false)
    },
    getRecord() {
      this.loading2 = true
      http.get('/zhaosheng/api/recruit/common/get-opt-history', { tbl_name:'user_thread', tbl_id: this.id }).then(res => {
        const { list = [] } = res
        this.list2 = list.map(i => ({ content: i.change_detail }))
        this.isRequest = true
      }).finally(() => this.loading2 = false)
    },
    handleSubmit(name) {
      this.$refs[name].validate(valid => {
        if(valid) {
          const { name, phone, ...other } = this.formData
          const data = { ...other }
          if(this.isAdd) {
            data.name = name
            data.phone = phone
          } else {
            data.id = this.id
          }
          if(data.relationship !== 8) data.real_relationship = ''
          const api =  `/zhaosheng/api/crm/${this.isAdd ? 'add-thread' : 'update-thread-info'}`
          this.loading = true
          http.post(api, data).then(res => {
            this.$router.back()
          }).finally(()=> this.loading = false)
        }
      })
    },
    handleBack(name) {
      this.$router.back()
    },
    handleVisible() {
      this.visible = true
      if(!this.isRequest) this.getRecord()
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-info {
  display: flex;
  flex-wrap: wrap;
  .item {
    &:last-child {
      padding-right: 14rem;
    }
    position: relative;
    padding: 10rem 100rem 20rem 10rem;
    color: #333;
    font-size: 16rem;
    line-height: 20rem;
    &::after {
      content: "";
      position: absolute;
      bottom: 4rem;
      left: 0;
      right: -2rem;
      height: 4rem;
      background-color: #1E3AA2;
      border-radius: 6rem;
    }
    .label {
      position: relative;
      margin-right: 20rem;
    }
    .value {
      position: relative;
      color: #1E3AA2;
      font-weight: 600;
    }
  }
}
.line-group {
  display: flex;
}
.line-item {
  &:not(:last-child) {
    margin-right: 50rem;
  }
  &:only-child {
    width: 70%;
  }
}
::v-deep {
  &.single-line {
    .el-form-item__label {
      float: none !important;
      word-break: break-word !important;
      font-weight: 600;
    }
    .el-form-item__content {
      margin-left: 0 !important;
    }
  }
}
</style>
